import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Tab, Nav, Image, OverlayTrigger, Tooltip, Spinner, ButtonGroup, InputGroup, Card, Overlay } from 'react-bootstrap';
import { editRoom, getAllRoomList } from "../../Firebase2/Storage2/dbRoom"
import { roomTypeParams_dict } from "../constants/global_definitions"
import GlobalDataContext from "../contexts/globaldataContext"
import { processRoomsStatus, reconcileRooms } from '../FirebaseApi/roomStatus_api';
import { checkComponentPresence, isEditable } from '../../util/ui_utils';
import { room_recalc_param_list } from '../constants/global_definitions';
import DomainContext from '../contexts/domainContext';
import { reconcileInterventions, reconcileWorkareas } from '../FirebaseApi/reconcileCloudFunctions';


export const ModalRoomtypeLinkedRooms = ({ roomtype, t, canWrite }) => {
    const { contract, structureElements } = useContext(GlobalDataContext)
    const { domain } = useContext(DomainContext)

    const contractId = contract.id

    const [showUpdate, setShowUpdate] = useState(false)
    const [room_list, setRoomList] = useState([])
    const [update_room_list, _setUpdateRoomList] = useState([])
    const roomtype_params_dict = roomTypeParams_dict({ t: t, contract_type: contract.type })
    const editable = isEditable(contract.status)
    const [param_list, _setParamList] = useState(Object.keys(roomtype_params_dict).filter(rp => editable || room_recalc_param_list.indexOf(rp) < 0))
    const [loading, setLoading] = useState(false)

    const needRecalc = useRef(false)



    useEffect(() => {
        console.log("DOMAIN", domain)

    }, [domain])

    const countRoom = async () => {
        let room_list = []

        for (const structure of structureElements.structure_list) {
            const t_room_list = await getAllRoomList({ structureId: structure.id })
            console.log(t_room_list)
            room_list = [...room_list, ...t_room_list]
        }
        const filtered = room_list.filter(room => room.roomtype.id === roomtype.id)
        setRoomList(filtered)
        console.log(filtered.length)
        const id_list = filtered.map(r => r.id)
        console.log(id_list)
        _setUpdateRoomList(id_list)
        setLoading(false)
    }
    const handleShowUpdate = () => {
        setLoading(true)
        countRoom()
        setShowUpdate(true)
    }
    const updateAllParams = () => {
        _setParamList(Object.keys(roomtype_params_dict).filter(rp => {
            if (editable) {
                console.log("IS EDITABLE")
                return true
            }
            if (room_recalc_param_list.indexOf(rp) < 0) {
                console.log("NOT RECALC", rp)
                return true
            }
            return false
        }
        ))
    }

    const handleCloseUpdate = () => {
        setShowUpdate(false)
    }

    const handleUpdate = async () => {
        let parent_edit_list = { 'pavillon': [], 'floor': [], 'department': [], }
        let department_childs = {}
        for (let r of room_list) {
            console.log("originalroom", r)
            for (const p of param_list) {
                console.log(p, roomtype[p])
                r[p] = roomtype[p] || false
            }
            /*             if (!editable) {
                            let editplanning = []
                            console.log("PLANNING", r.planning_event_list)
                            if (r.planning_event_list) {
                                editplanning = [...r.planning_event_list]
                            }
                            const new_elem_count = curItem.planning_event_list.length - pre_planning_length
                            const t_list = curItem.planning_event_list.slice(-new_elem_count)
                            console.log("planned", new_elem_count, t_list)
                            r.planning_event_list = [...editplanning, ...t_list]
                            r.next_update = curItem.next_update
            
                        } */
            if (parent_edit_list.pavillon.indexOf(r.parent_list[1]) === -1) {
                parent_edit_list.pavillon.push(r.parent_list[1])
            }
            if (parent_edit_list.floor.indexOf(r.parent_list[2]) === -1) {
                parent_edit_list.floor.push(r.parent_list[2])
            }
            if (parent_edit_list.department.indexOf(r.parent_list[3]) === -1) {
                parent_edit_list.department.push(r.parent_list[3])
                department_childs[r.parent_list[3]] = [r]
            } else {
                department_childs[r.parent_list[3]].push(r)
            }
            console.log("DOMAIN", domain)
            const return_data = editRoom(domain, contractId, r.parent_list[0], r.parent_list[1], r.parent_list[2], r.parent_list[3], r.id, r)
            console.log("editedroom", r, return_data)
        }
        if (param_list.some(r => room_recalc_param_list.includes(r))) {
            console.log("NEED RECALC parent_edit_list", domain, parent_edit_list, department_childs, room_list[0].parent_list[0])
            const r_data = await reconcileRooms(domain, contractId, room_list[0].parent_list[0])
            console.log("RECONCILE", r_data)
            if (r_data.error) {

            } else {
                console.log("process room status")
                await processRoomsStatus(domain, contractId, room_list[0].parent_list[0])
            }
            await reconcileWorkareas(domain, contractId)
            await reconcileInterventions(domain, contractId)
        }
    }


    const setUpdateRoomList = (el) => {
        let { name, value, type } = el
        let oldcheck = []
        try {
            oldcheck = [...update_room_list]
        } catch (err) { }
        if (el.checked) {
            if (oldcheck.indexOf(value) < 0) {
                oldcheck.push(value)
            }
        } else {
            if (oldcheck.indexOf(value) >= 0) {
                oldcheck.splice(oldcheck.indexOf(value), 1)
            }
        }
        value = oldcheck
        console.log(oldcheck, value)
        _setUpdateRoomList(oldcheck)
    }

    const setParamList = (el) => {
        let { value, } = el
        let oldcheck = []
        try {
            oldcheck = [...param_list]
        } catch (err) { }
        if (el.checked) {
            if (oldcheck.indexOf(value) < 0) {
                oldcheck.push(value)
            }
        } else {
            if (oldcheck.indexOf(value) >= 0) {
                oldcheck.splice(oldcheck.indexOf(value), 1)
            }
        }
        value = oldcheck
        console.log(oldcheck, value)
        _setParamList(oldcheck)
    }



    return (
        <>
            <Button variant="outline-info" onClick={() => handleShowUpdate()}>
                Locali associati
            </Button>
            <Modal show={showUpdate} onHide={handleCloseUpdate} fullscreen backdrop="static" className="bg-secondary">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {editable ?
                            <>
                                <FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.update_rooms')}</span>
                            </>
                            :
                            <>
                                <FontAwesomeIcon icon="info" /> <span className="text-info">Locali con questa tipologia</span>
                            </>
                        }

                        {loading &&
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {room_list.length > 0 ?

                        <Container fluid>
                            <Row className="border-bottom h5">
                                <Col sm="4" className="text-primary">
                                    {t('roomtype.labels.params_update')}
                                </Col>
                                <Col>
                                    <ButtonGroup classname="">
                                        <Button variant={param_list.length === Object.keys(roomtype_params_dict).length ? 'info' : "outline-info"} size="sm" onClick={() => updateAllParams()}><FontAwesomeIcon icon={['far', "check-square"]} />{t('roomtype.labels.revert_asroomtype')}</Button>
                                        <Button variant={param_list.length === 0 ? 'info' : "outline-info"} size="sm" onClick={() => _setParamList([])}><FontAwesomeIcon icon={['far', "square"]} />{t('global.labels.none')}</Button>
                                    </ButtonGroup>
                                </Col>

                            </Row>
                            {Object.keys(roomtype_params_dict).map((rtkey, k) => (
                                <Row className="border-bottom mb-3 pb-2" key={k}>
                                    <Col sm="1">
                                        <Form.Check type="checkbox" disabled={!editable && room_recalc_param_list.indexOf(rtkey) >= 0} className="ms-2" name="params" value={rtkey} checked={param_list.indexOf(rtkey) !== -1} label="" onChange={(event) => setParamList(event.currentTarget)} />
                                    </Col>
                                    {!editable && room_recalc_param_list.indexOf(rtkey) >= 0 ?
                                        <Col className="text-secondary font-italic">
                                            {roomtype_params_dict[rtkey].label}
                                            <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={<Tooltip >Non disponibile su contratti attivi</Tooltip>}>
                                                <FontAwesomeIcon icon="info-circle" />
                                            </OverlayTrigger>
                                        </Col>
                                        :
                                        <Col>
                                            {roomtype_params_dict[rtkey].label}
                                        </Col>
                                    }
                                </Row>
                            ))}
                            <Row className="border-bottom h5 mt-3">
                                <Col sm="7" className="text-primary">
                                    {t('global.labels.room_list')} ({room_list.length})
                                </Col>
                            </Row>
                            <Row className="border-bottom mb-3 pb-2">
                                <Col className="bg-secondary text-light ms-1" sm="1"></Col>
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.structure')} </Col>
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.pavillon')} </Col>
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.floor')} </Col>
                                <Col className="bg-secondary text-light ms-1">{t('global.labels.department')} </Col>
                                <Col className="bg-secondary text-light ms-1 font-weight-bold">{t('global.labels.room')}</Col>
                            </Row>
                            {room_list.map((room, k) => (
                                <Row key={k} className="element_row p-1">
                                    <Col sm="1">
                                        <Form.Check type="checkbox" className="ms-2" name="update_room_list" value={room.id} checked={update_room_list.indexOf(room.id) !== -1} label="" onChange={(event) => setUpdateRoomList(event.currentTarget)} />
                                    </Col>
                                    <Col>
                                        {structureElements.all_structure_dict[room.parent_list[0]].name}
                                    </Col>
                                    <Col>
                                        {structureElements.all_pavillon_dict[room.parent_list[1]].name}
                                    </Col>
                                    <Col>
                                        {structureElements.all_floor_dict[room.parent_list[2]].name}
                                    </Col>
                                    <Col>
                                        {structureElements.all_department_dict[room.parent_list[3]].name}
                                    </Col>
                                    <Col className="font-weight-bold">
                                        {room.name}
                                        {room.not_conformed ?
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{ show: 250, hide: 150 }}
                                                overlay=
                                                {<Tooltip id="button-tooltip">locale modificato rispetto alla tipologia associata</Tooltip>}
                                            ><Badge bg="warning" text="dark" size="md" className="me-1">
                                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                                </Badge>
                                            </OverlayTrigger>
                                            : <></>}
                                    </Col>
                                </Row>
                            ))}

                        </Container>
                        :
                        <Container>
                            Nessun locale associato a questa tipologia
                        </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdate}>
                        {t('modal.close')}
                    </Button>
                    {canWrite &&
                        <Button variant="primary" onClick={handleUpdate}>
                            <FontAwesomeIcon icon="save" /> {t('modal.save')}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )

}