import axios from 'axios';
import { GFUNC_DOMAIN } from '../constants/cloudfunctionparams'

import { auth } from '../../Firebase2/firebaseIndex';

///////////////////////////////////////////////////////////////////////////////
//
//	Ricalcola i risk_area_summary delle workareas di un contratto
//			domainId, 
//			contractId
//
///////////////////////////////////////////////////////////////////////////////
export const reconcileWorkareas = async (domainId, contractId) => {
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	const data = {
		headers: { Authorization: `Bearer ${authToken}` },
		domainId: domainId,
		contractId: contractId,
	};

	return axios
		.post(`${GFUNC_DOMAIN}/reconcileWorkareas`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
					console.log(error.response.data)
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}


///////////////////////////////////////////////////////////////////////////////
//
//	Ricalcola i risk_area_summary degli interventions di un contratto
//			domainId, 
//			contractId
//
///////////////////////////////////////////////////////////////////////////////
export const reconcileInterventions = async (domainId, contractId) => {
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	const data = {
		headers: { Authorization: `Bearer ${authToken}` },
		domainId: domainId,
		contractId: contractId,
	};

	return axios
		.post(`${GFUNC_DOMAIN}/reconcileInterventions`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
					console.log(error.response.data)
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}