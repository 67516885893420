import React, { useContext, useState, createRef, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col, Button, Modal, Spinner, Badge, OverlayTrigger, Popover, ListGroup, Tooltip, ButtonGroup, Card, Form, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import timelinePlugin from '@fullcalendar/timeline';
import { addMinutes, format, getDay, isSameDay } from 'date-fns'
import { useParams } from "react-router-dom"
import 'moment/locale/it';
import 'moment/locale/de';
import { it } from 'date-fns/locale'
import { firebase } from '../../Firebase2/firebaseIndex'

import { filterWeekDay, getValue, myTimestampToDate, compareName, getFloatNumber, getBaseObjectValue, getSelectedElementB, getTimeValue, compareDateParam, compareStartDateParam, rgba2hex, getSelectedElement } from "../../util/ui_utils.js"

import { EmployerIcon, ServiceBadge, ItemProgress, StructureGroupIcon, TicketStatus, IsDeleted, IsModified, SecondarySection } from '../StructureGraphicElements.js';
import { EventListDetail, EventStatus, filterCurrentDayRoom } from '../modal/ModalDashboardCalendarDetails';



import LocaleContext from "../contexts/locale.js"
import DomainContext from '../contexts/domainContext.js';
import GlobalDataContext from '../contexts/globaldataContext.js';
import DashboardContext from '../contexts/dashboardContext.js';

import { getInterventionById as getInterventionById2 } from '../../Firebase2/Storage2/dbIntervention'
import { getEventRange as getEventRange2, } from '../../Firebase2/Storage2/dbAgenda';
//import { getAllRoomList } from "../../Firebase2/Storage2/dbRoom"

import { getInterventionLogList } from '../../Firebase2/Storage2/dbInterventionLog';
import { interventionType_dict, popoverTiming, execution_color } from '../constants/global_definitions';
import { getEmployerById } from '../../Firebase2/Storage2/dbEmployers';


/* 


let userData = {}
try {
	userData = JSON.parse(localStorage.getItem('userData'))
} catch (err) {
	console.log('homeview userdataERROR', userData)
}

 */





const slot_duration_list = [
	'0:05:00',
	'0:15:00',
	'0:30:00',
	'1:00:00',
]


function compareWorkareaParam(a, b) {
	if (a.extendedProps.workarea_name < b.extendedProps.workarea_name) {
		return -1
	} else if (a.extendedProps.workarea_name > b.extendedProps.workarea_name) {
		return 1
	}
	if (myTimestampToDate(a.start) < myTimestampToDate(b.start)) {
		return -1;
	}
	if (myTimestampToDate(a.start) > myTimestampToDate(b.start)) {
		return 1;
	}

	return 0;
}


//----------------------------------------
//----------------------------------------
export const CalendarSummary = ({ t, userData }) => {
	const { intervention_dict, setInterventionDict,
		setOperatingUnit,
		setRASummary,
		today_room_dict, setTodayRoom,
		filterAppliedStructure,
		permission_data,
		event_status, setEventStatus,
		setHourSlotList,
		setLoadingStep,
		setLoadingGraph,
	} = useContext(DashboardContext)
	const { locale } = useContext(LocaleContext)
	const { contract, structureElements, roomtype_dict, risk_area_dict, service_dict, now } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)

	const [loading, setLoading] = useState(true)
	const [zoomIndex, setZoomIndex] = useState(2)
	const [showCompact, setShowCompact] = useState(false)
	const [claudit_trace, setClauditTrace] = useState(false)
	const [event_list, setEventList] = useState([])
	const [real_margin, setRealDateMargin] = useState({ start: '00:00:00', end: '24:00:00' })

	const calendarRef = createRef()

	let { cid } = useParams();
	let contractId = cid

	useEffect(() => {
		console.log("-----------CALENDAR-----------NOW", now, calendarRef.current)
		if (calendarRef.current) {

			let calendarApi = calendarRef.current.getApi()
			//console.log(calendarApi)
			calendarApi.gotoDate(now)
			setShowCompact(false)
		}

	}, [now])

	useEffect(() => {
		//console.log("####.....####...###..NEW FILTER -> ", filterAppliedStructure)
		try {
			console.log("-----------CALENDAR----------- filterStructure", calendarRef.current)
			let calendarApi = calendarRef.current.getApi()
			//console.log(calendarApi)
			calendarApi.refetchEvents('dattesta')
		} catch (err) {
			console.warn("ERR", err)
			//getEventList()
		}
	}, [filterAppliedStructure])

	useEffect(() => {
		//console.log("####.....####...###..NEW FILTER -> ", filterAppliedStructure)
		try {
			console.log("-----------CALENDAR-----------PERMISSION", permission_data, calendarRef.current)
			let calendarApi = calendarRef.current.getApi()
			//console.log(calendarApi)
			calendarApi.refetchEvents('dattesta')
		} catch (err) {
			console.warn("ERR", err)
			//getEventList()
		}
	}, [permission_data])


	const getNow = () => {
		return now
	}
	const getEventList = async (el, b) => {
		console.log("-----C EL*****", el, b, getNow())
		//		console.log("####--###----###GET EVENT LIST", filterAppliedStructure, event_status)
		console.log("contract", contract, domain, intervention_dict)
		setLoading(true)
		const c_now = new Date()
		let d_start = new Date(getNow().getTime())

		let d_end = new Date(getNow().getTime())
		d_start.setHours(0, 0, 0)
		d_end.setHours(23, 59, 0)

		let temp_intervention_dict = { ...intervention_dict }
		let unit_summary = { done: 0, doing: 0, undone: 0 }
		let temp_risk_area_summary = { done: {}, doing: {}, undone: {}, total: {}, suspended: {}, decommissioned: {} }

		const _filterAppliedStructure = localStorage.getItem('filterAppliedStructure')
		//		console.log(filterAppliedStructure, _filterAppliedStructure)
		if (contract && domain.length > 0) {
			//			const now = new Date()
			console.log("NOW", now, "start", d_start, d_end, el.start, el.end)
			contractId = contract.id
			console.log("HOME userData", userData, permission_data)
			const permission_department_list = permission_data.department_list
			const permission_structure_list = permission_data.structure_list
			const permission_workarea_list = permission_data.workarea_list

			let event_list = await getEventRange2(domain, contractId, el.start, el.end) || []
			console.log("EV LENGTH", event_list)
			event_list = event_list.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention' || e.extendedProps.type === 'requested_intervention') && !e.extendedProps.is_deleted)
			if (permission_workarea_list.length > 0) {
				event_list = event_list.filter(e => permission_workarea_list.indexOf(e.extendedProps.workarea_id) >= 0)
			} else if (permission_structure_list.length > 0) {
				if (permission_department_list.length > 0) {
					event_list = event_list.filter(e => checkEventPermission(e, permission_department_list))
				} else {
					event_list = event_list.filter(e =>
						(e.extendedProps.structure_list && e.extendedProps.structure_list.some(d => (permission_structure_list.indexOf(d)) >= 0))
					)
				}
			}

			let room_list = structureElements.room_list
			//			console.log(room_list)
			console.log("============ STRUCTURE FILTER", _filterAppliedStructure)
			if (_filterAppliedStructure && _filterAppliedStructure !== 'undefined') {
				room_list = room_list.filter(r => r.parent_list[0] === _filterAppliedStructure)
				event_list = event_list.filter(e => e.extendedProps.structure_list.indexOf(_filterAppliedStructure) >= 0)
				console.log("FILTERED_EVENT_LIST", _filterAppliedStructure, event_list)
			}

			let _hour_slot_list = []

			let slot_counter = 0
			let real_room_dict = Object.assign({}, ...room_list.map((x) => ({ [x.id]: x })));
			//			console.log("--REAL ROOM DICT", real_room_dict)
			let ev_status_list = await getInterventionLogList(domain, contractId, null, null, firebase.firestore.Timestamp.fromDate(d_start), firebase.firestore.Timestamp.fromDate(d_end))
			if (ev_status_list.filter(evs => evs.operator_data === undefined).length > 0) {
				let operator_dict = {}
				for (const evs of ev_status_list) {
					if (evs.operator_data) {
						operator_dict[evs.operator_id] = evs.operator_data
					} else {
						if (!operator_dict[evs.operator_id]) {
							const op = await getEmployerById(domain, contractId, evs.operator_id)
							if (!op.error) {
								operator_dict[evs.operator_id] = op
							}
						}
						if (operator_dict[evs.operator_id]) {
							evs.operator_data = operator_dict[evs.operator_id]
						}
					}
				}
			}
			const ev_status_dict = Object.assign({}, ...ev_status_list.map((x) => ({ [x.event_id]: x })));
			setEventStatus(ev_status_dict)
			for (const one_event of event_list) {
				//console.log(permission_department_list, event_list[d].extendedProps.department_list, event_list[d].extendedProps.structure_list,)
				/* 				if ( permission_department_list.length === 0 || 
									(event_list[d].extendedProps.department_list &&  event_list[d].extendedProps.department_list.some(d => (permission_department_list.indexOf(d)) >= 0) )){
									 console.log(hour_slot_list) */
				if (true) {
					one_event.start = myTimestampToDate(one_event.start)
					one_event.end = myTimestampToDate(one_event.end)

					if (slot_counter === 0 || _hour_slot_list[_hour_slot_list.length - 1].end < myTimestampToDate(one_event.start)) {
						_hour_slot_list.push({ start: myTimestampToDate(one_event.start), end: myTimestampToDate(one_event.end), operating_unit: 0, service_count: 0 })
					} else {
						if (myTimestampToDate(one_event.end) > _hour_slot_list[_hour_slot_list.length - 1].end) {
							_hour_slot_list[_hour_slot_list.length - 1].end = myTimestampToDate(one_event.end)
						}
					}
					let cur_slot = _hour_slot_list[_hour_slot_list.length - 1]
					slot_counter += 1
					const intervention = temp_intervention_dict[one_event.extendedProps.intervention_id] || await getInterventionById2(domain, contractId, one_event.extendedProps.intervention_id)
					console.log("DB INTERVENTION", intervention)
					if (intervention) {
						temp_intervention_dict[one_event.extendedProps.intervention_id] = intervention
						one_event.extendedProps.name = intervention.name + " " + getValue(intervention.service, 'name')
						one_event.extendedProps.intervention_data = intervention
						one_event.extendedProps.summary = "Totale Locali: " + intervention.room_count
						if (intervention.room_list) {
							cur_slot.operating_unit += intervention.operating_units
							cur_slot.service_count += 1
							const filtered_room_list = (intervention.type === 'periodic' || intervention.type === 'requested') ? intervention.room_list :
								intervention.room_list.filter(room =>
								{
									console.log(room.id, roomtype_dict[room.roomtype.id], intervention.service, getSelectedElementB(
										intervention.service?.id, roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].service_list : [], 'service', 'id'
									) )
									return filterWeekDay(getSelectedElementB(
										intervention.service?.id, roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].service_list : [], 'service', 'id'
									).weekdays, one_event.start, intervention.custom_weekdays, room.id)
								})

									console.log("FILTERED ROOM LIST", filtered_room_list.length)
							for (const room of filtered_room_list) {
								if (!real_room_dict[room.id]) {
									const real_room = structureElements.all_room_dict[room.id]
									//									const real_room = await getRoomById(domain, contractId, room.parent_list[0], room.parent_list[1], room.parent_list[2], room.parent_list[3], room.id)
									console.log(real_room)
									if (!real_room || real_room.error) {

									} else {
										//	console.log(real_room)
										real_room_dict[room.id] = real_room
									}
								}
								const real_room = real_room_dict[room.id]
								if (!real_room || !real_room.risk_area ) {
									console.log("UNDEFINED", room)
								} else {

									console.log(real_room)
									setLoadingStep(slot_counter)
									console.log("RISK AREA DICT", real_room, risk_area_dict[real_room.risk_area?.Id], risk_area_dict)
									if (real_room.status === 'active') {
										if (myTimestampToDate(one_event.start) <= c_now && myTimestampToDate(one_event.end) >= c_now) {
											if (temp_risk_area_summary.doing[real_room.risk_area.Id]) {
												temp_risk_area_summary.doing[real_room.risk_area.Id].count += 1
												temp_risk_area_summary.doing[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
											} else {
												temp_risk_area_summary.doing[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
											}
										} else if (myTimestampToDate(one_event.end) > c_now) {
											if (temp_risk_area_summary.undone[real_room.risk_area.Id]) {
												temp_risk_area_summary.undone[real_room.risk_area.Id].count += 1
												temp_risk_area_summary.undone[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
											} else {
												temp_risk_area_summary.undone[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
											}
										} else {
											if (temp_risk_area_summary.done[real_room.risk_area.Id]) {
												temp_risk_area_summary.done[real_room.risk_area.Id].count += 1
												temp_risk_area_summary.done[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
											} else {
												temp_risk_area_summary.done[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
											}
										}
										if (temp_risk_area_summary.total[real_room.risk_area.Id]) {
											temp_risk_area_summary.total[real_room.risk_area.Id].count += 1
											temp_risk_area_summary.total[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
										} else {
											temp_risk_area_summary.total[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
										}
									} else if (real_room.status === 'suspended') {
										if (cur_slot.suspended) {
											cur_slot.suspended += 1
											one_event.extendedProps.suspended += 1
										} else {
											cur_slot.suspended = 1
											one_event.extendedProps.suspended = 1
										}
										if (temp_risk_area_summary.suspended[real_room.risk_area.Id]) {
											temp_risk_area_summary.suspended[real_room.risk_area.Id].count += 1
											temp_risk_area_summary.suspended[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
										} else {
											temp_risk_area_summary.suspended[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
										}
									} else {
										if (cur_slot.decommissioned) {
											cur_slot.decommissioned += 1
											one_event.extendedProps.decommissioned += 1
										} else {
											cur_slot.decommissioned = 1
											one_event.extendedProps.decommissioned = 1
										}
										if (temp_risk_area_summary.decommissioned[real_room.risk_area.Id]) {
											temp_risk_area_summary.decommissioned[real_room.risk_area.Id].count += 1
											temp_risk_area_summary.decommissioned[real_room.risk_area.Id].size += getFloatNumber(real_room.size)
										} else {
											temp_risk_area_summary.decommissioned[real_room.risk_area.Id] = { ...risk_area_dict[real_room.risk_area.Id], count: 1, size: getFloatNumber(real_room.size) }
										}
									}
								}
							}
							if (myTimestampToDate(one_event.start) <= c_now && myTimestampToDate(one_event.end) >= c_now) {
								unit_summary.doing += parseInt(intervention.operating_units)
							} else if (myTimestampToDate(one_event.end) > c_now) {
								console.log("UNDONE", myTimestampToDate(one_event.end) > c_now)
								unit_summary.undone += parseInt(intervention.operating_units)
							} else {
								unit_summary.done += parseInt(intervention.operating_units)
							}
						}
					}
				}
			}
			if (event_list.length > 0) {
				const ordevent_list = event_list.sort(compareStartDateParam)
				const real_start_h = myTimestampToDate(ordevent_list[0].start).getHours()
				const real_end_h = myTimestampToDate(ordevent_list[event_list.length - 1].end).getHours() + 1

				if (real_start_h < real_end_h) {
					setRealDateMargin({ start: `${real_start_h}:00:00`, end: `${real_end_h}:00:00` })
				} else {
					setRealDateMargin({ start: `${real_start_h}:00:00`, end: `24:00:00` })

				}
				event_list = event_list.sort(compareWorkareaParam)
			}
			console.log("event_list", event_list.map(e => ({ ...e, start: myTimestampToDate(e.start), end: myTimestampToDate(e.end), resourceId: 1 })))
			setHourSlotList(_hour_slot_list)
			console.log("hour slot list", _hour_slot_list)
			setInterventionDict(temp_intervention_dict)
			console.log("UPDATE DATA FOR OTHER SECTION", event_list.length, unit_summary, event_list)
			setOperatingUnit(unit_summary)
			//console.log(real_room_dict)
			setTodayRoom(real_room_dict)
			setLoading(false)

			setLoadingGraph(true)
			let ra_graph = []
			let graph_risk_area_summary = [{ name: 'eseguito' }, { name: 'in esecuzione' }, { name: 'non eseguito' }]
			let graph_risk_area_summarysize = [{ name: 'eseguito' }, { name: 'in esecuzione' }, { name: 'non eseguito' }]
			let present_ra = []
			console.log("\t\t\tTEMP SUMMARY RA", temp_risk_area_summary)
			setRASummary(temp_risk_area_summary)
			Object.keys(temp_risk_area_summary).map((d, k) => {
				const rd = temp_risk_area_summary[d]
				//console.log("RD summary", rd)
				Object.keys(rd).map(ra => {
					if (graph_risk_area_summary[k]) {
						ra_graph.push({ name: rd[ra].name ? rd[ra].name[locale] : '', room_count: rd[ra].count, size: rd[ra].size })
						if (graph_risk_area_summary[k][ra]) {
							graph_risk_area_summary[k][ra] += rd[ra].count
							graph_risk_area_summarysize[k][ra] += rd[ra].size
						} else {
							graph_risk_area_summary[k][ra] = rd[ra].count
							graph_risk_area_summarysize[k][ra] = rd[ra].size
							if (present_ra.indexOf(ra) === -1) {
								present_ra.push(ra)
							}
						}
					}
				})
				return rd
			})

			//setRiskAreaSummary([present_ra, graph_risk_area_summary, graph_risk_area_summarysize])
			setLoadingGraph(false)
			setEventList(event_list)
			return event_list.map(e => ({ ...e, start: myTimestampToDate(e.start), end: myTimestampToDate(e.end), resourceId: 1 }))
		} else {
			console.error('problem')

		}

	}


	const handleEventsSet = (events) => {
		console.log("filterstructure", filterAppliedStructure, events)
		return events

	}

	const zoomPlus = () => {
		if (zoomIndex > 0) {
			setZoomIndex(zoomIndex - 1)
		}
	}


	const zoomMinus = () => {
		if (zoomIndex < slot_duration_list.length - 2) {
			setZoomIndex(zoomIndex + 1)
		}
	}
	const renderMyEventContent = (info) => {
		return renderEventContent(info, roomtype_dict, t, structureElements.all_room_dict, service_dict, structureElements.all_department_dict, now, event_status)
	}


	//=======================================================================================================
	return (

		permission_data ?
			<>
				<Row>
					{!showCompact ?
						<Col>
							<ButtonGroup className="me-2">
								<Button variant="secondary" size="sm" onClick={zoomPlus} disabled={zoomIndex === 0}><FontAwesomeIcon icon="search-plus" /></Button>
								<Button variant="secondary" size="sm" onClick={zoomMinus} disabled={zoomIndex === slot_duration_list.length - 1}><FontAwesomeIcon icon="search-minus" /></Button>
							</ButtonGroup>
						</Col>
						: ''}
					<Col>
						<SectionEventList t={t} isLoading={loading} today_room_dict={today_room_dict} showCompact={showCompact} setShowCompact={setShowCompact} event_status={event_status} claudit_trace={claudit_trace} setEventStatus={setEventStatus} event_list={event_list} userData={userData} />
					</Col>
				</Row>
				{!showCompact ?
					<FullCalendar

						ref={calendarRef}
						schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
						plugins={[interactionPlugin, resourceTimelinePlugin, timelinePlugin]}
						headerToolbar={true}
						buttonText={
							{
								today: 'oggi',
								month: 'mese',
								week: 'settimana',
								day: 'giorno',
								list: 'lista'
							}

						}
						firstDay="1"
						locale='it'
						views={{
							timelineTenDay: {
								type: 'timeline',
								duration: { days: 1 }
							},
						}}
						slotDuration={slot_duration_list[zoomIndex]}
						resourceAreaHeaderContent="ora"
						nowIndicator={true}
						initialView='resourceTimeline'
						resources={[{ id: 1, title: '' }]}
						eventResize={false}
						editable={false}
						selectable={false}
						slotMinTime={real_margin.start}
						slotMaxTime={real_margin.end}
						height='auto'
						resourceAreaWidth="1%"
						eventContent={renderMyEventContent}
						eventsSet={handleEventsSet}
						duration={{ days: 1 }}
						initialEvents={getEventList}
					/>
					: ''}

			</>
			: ''
	)

}




// --------------------------------------------------------
const renderEventContent = (info, roomtype_dict, t, all_room_dict, service_dict, all_department_dict, now, event_status) => {
	if (info.timeText.length === 2) {
		info.timeText = info.timeText + ":00"
	}
	const intervention_type_dict = interventionType_dict({ t })
	let evtId = "event-" + info.event.id;
	let tt = ''
	let room_count = ''
	try {
		tt = format(myTimestampToDate(info.event.start), 'HH:mm', { locale: it }) + " - " + format(myTimestampToDate(info.event.end), 'HH:mm', { locale: it })
	} catch (err) {
		//console.log(info.event.start, info.event.end)
	}
	if (info.event.extendedProps.type === 'ordinary_intervention') {
		try {
			room_count = info.event.extendedProps.intervention_data.room_list?.filter(_room => filterCurrentDayRoom(info.event, all_room_dict[_room.id], roomtype_dict)).length
		} catch (err) {
			console.error(err)
		}
	} else {
		room_count = info.event.extendedProps.intervention_data.room_list?.length
	}

	const slotpopover = (
		<Popover id="popover-basic">
			<Popover.Header as="h3">{intervention_type_dict[info.event.extendedProps.type].title}:&nbsp;{info.event.extendedProps.intervention_data.name}</Popover.Header>
			<Popover.Body>
				<Container>
					<Row>
						<Col>
							<IsModified is_modified={info.event.extendedProps.is_modified} legend={true} />
							<ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service?.id]} freq={true} />
						</Col>
					</Row>
					<Row>
						<Col>
						{console.log("intervention",info.event.extendedProps.intervention_data )}
							<SecondarySection ev={info.event} intervention_data={info.event.extendedProps.intervention_data} />
						</Col>
					</Row>
					<Row>
						<Col>
							{tt}
							&nbsp;|&nbsp;
							<EmployerIcon operating_units={info.event.extendedProps.intervention_data.operating_units} employer_list={info.event.extendedProps.employer_list} />
						</Col>
					</Row>
					{info.event.extendedProps.employer_list && info.event.extendedProps.employer_list.length > 0 ?
						<>
							{info.event.extendedProps.employer_list.map((e, k) => (

								<Row key={k}>
									<Col>
										<FontAwesomeIcon icon="user" />&nbsp;
										{e.code}&nbsp;
										{e.lastname} &nbsp;
										{e.firstname}
									</Col>
								</Row>
							))}
						</>
						: ''}
					<Row>
						<Col>
							{info.event.extendedProps.department_list ?
								<>
									{info.event.extendedProps.department_list.map((d, k) => (
										<Badge bg="light" text="dark" key={k} className="me-1">
											{all_department_dict[d].name}
										</Badge>
									))}
								</>
								: ''}
						</Col>
					</Row>
					<Row>
						<Col>
							{room_count}&nbsp;locali {info.event.extendedProps.suspended ? <Badge bg="danger"><FontAwesomeIcon icon={'hourglass'} /> sospesi: {info.event.extendedProps.suspended}</Badge> : ''} {info.event.extendedProps.decommissioned ? <Badge bg="warning" text="dark"><FontAwesomeIcon icon={'exclamation-circle'} /> dismessi: {info.event.extendedProps.decommissioned}</Badge> : ''}
						</Col>
					</Row>
					<Row>
						<Col>
							{info.event.extendedProps.intervention_data.risk_area_summary ? <ItemProgress risk_list={info.event.extendedProps.intervention_data.risk_area_summary} item={info.event.extendedProps.intervention_data} /> : ''}
						</Col>
					</Row>
					{event_status[info.event.id] &&
						<EventStatus one_event_status={event_status[info.event.id]} room_count={room_count} intervention={info.event.extendedProps.intervention_data} />
					}
				</Container>
			</Popover.Body>
		</Popover>
	);





	let bg_color = ''
	let c_now = isSameDay(now, new Date()) ? now : new Date()
	if (info.event.start <= c_now && info.event.end >= c_now) {//doing
		bg_color = execution_color.doing
	} else if (info.event.end < c_now) {//done
		bg_color = execution_color.done
	} else {//undone
		bg_color = execution_color.undone
	}
	//console.log("start", info.event.start, "end", info.event.end, "now", now, bg_color)
	if (info.event.extendedProps.type === 'ordinary_intervention' || info.event.extendedProps.type === 'periodic_intervention' || info.event.extendedProps.type === 'requested_intervention') {
		try {

			let end_validation = c_now
			if (event_status[info.event.id]) {
				end_validation = addMinutes(myTimestampToDate(event_status[info.event.id].end), info.event.extendedProps.intervention_data.max_check_delay)
			}
			return (
				<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" delay={popoverTiming} overlay={slotpopover}>
					<div className="fc-content " style={{ fontSize: '7px', lineHeight: '9px', backgroundColor: bg_color, borderColor: getEventBorderStatus(event_status[info.event.id], end_validation, now), borderStyle: 'solid', borderWidth: 2 }} id={evtId}>
						{info.event.extendedProps.suspended ?
							<FontAwesomeIcon icon={'hourglass'} className="ms-1 text-danger" />
							: ''}
						{info.event.extendedProps.decommissioned ?
							<FontAwesomeIcon icon={'exclamation-circle'} className="ms-1 text-dark" />
							: ''}
						<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
						<IsModified is_modified={info.event.extendedProps.is_modified} />
						&nbsp;
						{event_status[info.event.id] ?
							<FontAwesomeIcon icon="check" /> : ''}
					</div>
				</OverlayTrigger>
			)
		} catch (err) {
			return (
				<>
					<b>{info.timeText}</b>
					<br />
				</>
			)

		}
	}
	else {
		return (
			<>
				<b>&nbsp;</b>
			</>
		)

	}

}

//------------------
function checkEventPermission(e, permission_department_list) {
	console.log(e.extendedProps.department_list, permission_department_list)
	try {
		console.log(permission_department_list, e.extendedProps.department_list.some(d => (permission_department_list.indexOf(d)) >= 0))
	} catch (err) {
		console.log(err)
	}
	if (e.extendedProps.department_list && e.extendedProps.department_list.some(d => (permission_department_list.indexOf(d)) >= 0)) {
		console.log("IS PRESENT", e.extendedProps.department_list)
		return true
	} else {
		return false
	}

}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function SectionEventList({ t, isLoading, today_room_dict, showCompact, setShowCompact, event_status, claudit_trace, setEventStatus, event_list, userData }) {
	const { hour_slot_list } = useContext(DashboardContext)

	return (
		<>
			{isLoading ?
				<Row>
					<Col>
						<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
					</Col>
				</Row>
				:
				hour_slot_list.length > 0 ?
					<Row>
						<Col className="text-end">
							<EventListDetail event_list={event_list} t={t} today_room_dict={today_room_dict} event_status={event_status} claudit_trace={claudit_trace} setEventStatus={setEventStatus} userData={userData} />
							<ButtonGroup>
								<Button size="sm" variant={showCompact ? "secondary" : "outline-secondary"} onClick={() => setShowCompact(!showCompact)} className="ms-2">Compatta</Button>
								<Button size="sm" variant={!showCompact ? "secondary" : "outline-secondary"} onClick={() => setShowCompact(!showCompact)}>Estesa</Button>
							</ButtonGroup>
						</Col>
					</Row>
					:
					<i>{t('event.messages.no_event')}</i>

			}

			{showCompact ?
				<>
					{hour_slot_list && hour_slot_list.length > 0 ?
						<>
							<Row>
								<Col sm="1">
									<Badge bg="dark">
										ora
									</Badge>
								</Col>
								<Col>
									<DayHourOccupation eventhour_slot_list={hour_slot_list} />
								</Col>
							</Row>
							<Row className="p-1 text-light">
								<Col className="bg-secondary ms-1">numero servizi</Col>
								<Col className="bg-secondary ms-1">inizio</Col>
								<Col className="bg-secondary ms-1">fine</Col>
								<Col className="bg-secondary ms-1">note</Col>
							</Row>
							{hour_slot_list.map((e, k) => (
								<Row key={k + e.id}>
									<Col>
										{e.service_count}
									</Col>
									<Col>
										{getTimeValue(e.start)}
									</Col>
									<Col>
										{getTimeValue(e.end)}
									</Col>
									<Col>
										{e.suspended ?

											<OverlayTrigger
												placement="right"
												delay={popoverTiming}
												overlay={<Tooltip id="button-tooltip">locali sospesi</Tooltip>}
												message="locali sospesi"
											>
												<Badge bg="danger">{e.suspended}</Badge>
											</OverlayTrigger>
											: ''}
										{e.decomissioned ?
											<OverlayTrigger
												placement="right"
												delay={popoverTiming}
												overlay={<Tooltip id="button-tooltip">locali dismessi</Tooltip>}
												message="locali dismessi"
											>
												<Badge bg="warning" text="dark">{e.decomissioned}</Badge>
											</OverlayTrigger>
											: ''}
									</Col>
								</Row>
							))}
						</> : ''}
				</> : ''}
		</>
	)
}









/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getEventBorderStatus = (one_event_status, end_validation, now) => {
	if (one_event_status) {
		if (one_event_status.validate_status === 'true') {
			return '#28a745'
		} else if (one_event_status.validate_status === 'false') {
			return '#dc3545'
		} else {
			if (end_validation >= now) {
				return '#ffc107'
			}
			return '#007bff'
		}
	} else {
		return '#007bff'
	}

}










/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const DayHourOccupation = ({ eventhour_slot_list }) => {
	const { now } = useContext(GlobalDataContext)
	let hour_list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
	let hour_list2 = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
	hour_list = hour_list.map(h => (
		[
			{ string: h, full: getHourStatus(h, 0), now: getIsNow(h, 0) },
			{ string: ' ', full: getHourStatus(h, 30), now: getIsNow(h, 30) },
		]
	))
	hour_list2 = hour_list2.map(h => (
		[
			{ string: h, full: getHourStatus(h, 0), now: getIsNow(h, 0) },
			{ string: ' ', full: getHourStatus(h, 30), now: getIsNow(h, 30) },
		]
	))
	function getIsNow(h, m) {
		let view_slot_start = new Date()
		let view_slot_end = new Date()
		view_slot_start.setSeconds(0)
		view_slot_end.setSeconds(0)
		if (m === 0) {
			view_slot_start.setHours(h, 0, 0)
			view_slot_end.setHours(h, 29, 0)
		} else {
			view_slot_start.setHours(h, 30, 0)
			view_slot_end.setHours(h + 1, 0, 0)
		}
		if (view_slot_start <= now && view_slot_end >= now) {
			return 0
		} else if (view_slot_end < now) {
			return -1
		} else {
			return 1
		}

	}
	function getHourStatus(h, m) {
		if (eventhour_slot_list) {
			let is_full = false
			let view_slot_start = new Date()
			let view_slot_end = new Date()
			view_slot_start.setSeconds(0)
			view_slot_end.setSeconds(0)
			view_slot_end.setMilliseconds(0)
			view_slot_start.setMilliseconds(0)
			eventhour_slot_list.map(hd => {
				let d_s = new Date(hd.start)
				let d_e = new Date(hd.end)
				let d_sm = d_s.getMinutes()
				let d_em = d_e.getMinutes()
				if (d_sm >= 0 && d_sm <= 29) {
					d_s.setMinutes(0)
				} else {
					d_s.setMinutes(30)
				}
				if (d_em >= 0 && d_em <= 29) {
					d_e.setMinutes(0)
				} else {
					d_e.setHours(d_e.getHours() + 1)
					d_e.setMinutes(0)
				}
				d_s.setSeconds(0)
				d_s.setMilliseconds(0)
				d_e.setSeconds(0)
				d_e.setMilliseconds(0)
				view_slot_start.setHours(h)
				if (m === 0) {
					view_slot_start.setMinutes(0)
					view_slot_end.setHours(h)
					view_slot_end.setMinutes(29)
				} else {
					view_slot_start.setMinutes(30)
					view_slot_end.setHours(h + 1)
					view_slot_end.setMinutes(0)
				}
				console.log(getTimeValue(view_slot_start), getTimeValue(view_slot_end), getTimeValue(d_s), getTimeValue(d_e))
				if (view_slot_start >= d_s && view_slot_end <= d_e) {
					if (view_slot_start <= now && view_slot_end >= now) {//doing
						is_full = execution_color.doing
					} else if (view_slot_end < now) {//done
						is_full = execution_color.done
					} else {//undone
						is_full = execution_color.undone
					}
					console.log(is_full)
				}
			})
			return is_full
		}
		return false
	}

	return (
		<>
			<Row>
				<Col>
					<Row>
						{hour_list.map((h, k) => (
							<>
								<Col key={k} className="p-0">
									<Badge bg={h[0].now === 0 ? "dark" : 'light'} text={h[0].now === 0 ? "light" : 'dark'}> {h[0].string}</Badge>
								</Col>
								<Col key={k + 24} className="p-0">
									<Badge bg={h[1].now === 0 ? "dark" : 'light'} text={h[0].now === 0 ? "light" : 'dark'}> &nbsp;</Badge>
								</Col>
							</>
						))}
					</Row>
				</Col>
				<Col>
					<Row>
						{hour_list2.map((h, k2) => (
							<>
								<Col key={k2} className="p-0">
									<Badge bg={h[0].now === 0 ? "dark" : 'light'} text={h[0].now === 0 ? "light" : 'dark'}> {h[0].string}</Badge>
								</Col>
								<Col key={k2 + 24} className="p-0">
									<Badge bg={h[1].now === 0 ? "dark" : 'light'} text={h[0].now === 0 ? "light" : 'dark'}> &nbsp;</Badge>
								</Col>
							</>
						))}
					</Row>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						{hour_list.map((h, k) => (
							<>
								<Col key={k} style={{ backgroundColor: h[0].full }} className={`p-1 ` + (!h[0].full ? 'text-secondary ' : '')}>
									<span className={h[0].now ? 'text-warning' : ''}> &nbsp;</span>
								</Col>
								<Col key={k + 24} style={{ backgroundColor: h[1].full }} className={`p-1 ` + (!h[1].full ? 'text-secondary ' : '')}>
									<span className={h[1].now ? 'text-warning' : ''}> &nbsp;</span>
								</Col>
							</>
						))}
					</Row>
				</Col>
				<Col>
					<Row>
						{hour_list2.map((h, k2) => (
							<>
								<Col key={k2} style={{ backgroundColor: h[0].full }} className={`p-1 ` + (!h[0].full ? 'text-secondary ' : '')}>
									<span className={h[0].now ? 'text-warning' : ''}> &nbsp;</span>
								</Col>
								<Col key={k2 + 24} style={{ backgroundColor: h[1].full }} className={`p-1 ` + (!h[1].full ? 'text-secondary ' : '')}>
									<span className={h[1].now ? 'text-warning' : ''}> &nbsp;</span>
								</Col>
							</>
						))}
					</Row>
				</Col>
			</Row>
		</>
	)
}












