import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputGroup, Container, FormControl, Row, Col, Button, ButtonGroup, Spinner, Image } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'
import { format, subDays } from 'date-fns'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import { checkComponentPresence, compareName, compareParam, myTimestampToDate, propComparator } from "../util/ui_utils.js"

import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"
import ModalRoomTypeImage from '../components/modal/ModalRoomTypeImage'


import { getDomainList as getDomainList2 } from '../Firebase2/Storage2/dbDomain';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';
import { delGlobalImage, delImage, getImageList } from '../Firebase2/Storage2/dbImage.js';
import DomainContext from '../components/contexts/domainContext.js';
import { contractScope_dict, contractType_dict } from '../components/constants/global_definitions.js';
import DeleteconfirmationSection from '../components/DeleteConfirmationSection.js';
import AlertSection from '../components/AlertSection.js';





const contractId = sessionStorage.getItem('contractId')

const newroomtypeimage = {
	name: '',
	code: '',
	logo: '',
	basemail: '',
	/* 	startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(), */
}



const RoomTypeDomainImageView = ({ t }) => {

	const { domain } = useContext(DomainContext)

	const childRefDelete = useRef()
	const childRefAlert = useRef()
	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [curElement, setCurrentElement] = useState(newroomtypeimage)
	const [searchTerm, setSearchTerm] = useState('')



	const contract_type_dict = contractType_dict({ t })
	const contract_scope_dict = contractScope_dict({ t })


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}





	const initValues = async () => {
		setLoading(true)
		let element_list = await getImageList(domain)
		console.log("image_list", element_list)
		element_list.sort(propComparator('description'))
		setElementList(element_list)
		setLoading(false)
	}

	const handleShowDelete = (el) => {
		const cb = async () => {
			const return_data = await delImage(domain, el.Id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'Id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		console.log(childRefDelete)
		childRefDelete.current.confirmDelete(t('objecttype.messages.delete_title') + " immagine: " + el.description, t('objecttype.messages.delete_message') + "</br><b>" + el.description + "?", cb)
	}

	useEffect(() => {
		initValues()
	}, [])

	return (
		<div>
			<DeleteconfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col sm={4}>
					{element_list?.length} immagini
						{/* 						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
 */}					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalRoomTypeImage is_new={true} t={t} item_list={element_list} setParentList={setElementList} />
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" sm="1" md="1">Anteprima</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.description')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.contract_type')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.contract_scope')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list!== undefined && element_list.length > 0 ?
					<>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className="p-1 border-bottom" >
								<Col sm="1" md="1">
									<Row>
										{i.media && i.media.length > 0
											?
											<Image style={{ maxWidth: '50px' }} src={i.media} fluid className="me-1" />
											: ''}
									</Row>
								</Col>
								<Col>
									{i.description}
								</Col>
								<Col>
									{i.contract_type_list.map(i => `${contract_type_dict[i]?.label}, `)}
								</Col>
								<Col>
									{i.contract_scope_list.map(i => `${contract_scope_dict[i]?.label}, `)}
								</Col>
								<Col className="text-center" sm="2" md="1">
									<ButtonGroup>
										<ModalRoomTypeImage t={t} item={i} setItem={onChangeHandler} item_list={element_list} setParentList={setElementList} index={key} canWrite={true} />
										<Button variant="danger" size="sm" onClick={() => handleShowDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> Nessuna immagine caricata nel dominio
					</>
				}
			</Container>
		</div>
	);
}


export default translate()(RoomTypeDomainImageView)


