import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container,  Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalTrainer from "../components/ModalTrainer"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import { SearchSection } from "../components/SearchSection"

import { InitContractHelper } from '../util/contract.js';
import { checkComponentPresence, compareName,  getElementPreview, getBaseObjectValue, getElementCount } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'


import { getTrainerList, deleteTrainer, getDomainTrainerList} from '../Firebase2/Storage2/dbTrainers';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
let load_page =false 
const section = 'trainer'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const TrainersDomainView = ({ t, uData }) => {

	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()


	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		console.log(uData)
			setWritePage(true)
		setLoading(true)
		console.log(domain,  companyId)
		if (domain.length > 0) {

			const brand_list = await getBaseObjectTypeList2(domain, 'brands')
			const category_list = await getBaseObjectTypeList2(domain, 'trainer_category')
			setUiList({ ...ui_list, brand_list, category_list })
			let newelement_list = await getDomainTrainerList(domain)
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			setLoading(false)
			load_page = true
		} else {
			console.log("init contract return")

		}
	}





	useEffect(() => {
		console.log('trainer loadingcontract', load_page)
			initValues()
	}, [])

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data =await deleteTrainer(domain, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('trainer.messages.delete_trainer_title'), t('trainer.messages.delete_trainer', { name: el.lastname  + " " + el.firstname}), cb)
	}







	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<Container fluid>
				<Row className="mt-1">
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<ModalTrainer is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" sm="2">{t('global.labels.code')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.lastname')}</Col>
						<Col className="bg-secondary ms-1"></Col>
						<Col className="bg-secondary ms-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}
			</Container>
			<Container fluid>
			<AlertSection ref={childRefAlert} />
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.register.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom " >
										<Col sm="2">{i.code}</Col>
										<Col><b>{i.firstname}</b></Col>
										<Col><b>{i.lastname}</b></Col>
										<Col>{i.typology ==='internal'? 'interno':'esterno'}</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalTrainer is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <>
								<Row>
									<Col className="font-italic">
										{t('trainers.messages.no_trainer')}
									</Col>
								</Row>
							</>
						}
					</>
				}



			</Container>
		</>
	);
}




export default translate()(TrainersDomainView)



