import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addImage = async (domainId, data ) => {
    console.log(domainId, data)
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('IMAGES')
            .add(data);
        const newdata = {
            ...data,
            Id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addImage error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getImageById = async (domainId, imageId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('IMAGES')
			.doc(imageId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            Id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getImageById error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getImageList = async (domainId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('IMAGES')
            .get();

        const imageList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                Id : doc.id,
            }
            return data
        })
        return imageList;  
    } catch (e) {
        console.log("getImageList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editImage = async (domainId, imageId, data) => {
    try {
        const image = await  firestore.collection('DOMAINS')
            .doc(domainId).collection('IMAGES')
            .doc(imageId);

        const res = await image.set(
            data
        ).then( () => {
			console.log("editImage - edit successfully")
			return data
		}).catch( error => {
			console.log("editImage - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editImage error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const delImage = async (domainId,imageId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('IMAGES')
            .doc(imageId).delete();
        return res
    }  catch (e) {
        console.log("delImage error: ", e)
        return { error: e }
    }
}


//////////////////////////////////////////////// GLOBAL ///////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////
export const addGlobalImage = async (data ) => {
    // console.log(data)
    try {
        const res = await firestore.collection('GLOBAL_IMAGES')
            .add(data);
        const newdata = {
            ...data,
            Id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addImage error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getGlobalImageById = async (imageId) => {
    try {
        const res = await firestore.collection('GLOBAL_IMAGES')
			.doc(imageId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            Id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getImageById error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getGlobalImageList = async () => {
    try {
        const res = await firestore.collection('GLOBAL_IMAGES')
            .get();

        const imageList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                Id : doc.id,
            }
            return data
        })
        return imageList;  
    } catch (e) {
        console.log("getImageList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editGlobalImage = async (imageId, data) => {
    try {
        const image = await firestore.collection('GLOBAL_IMAGES')
            .doc(imageId);

            console.log("image", image)
        const res = await image.set(
            data
        ).then( () => {
			console.log("editGlobalImage - edit successfully")
			return data
		}).catch( error => {
			console.log("editGlobalImage - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editGlobalImage error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const delGlobalImage = async (imageId) => {
    try {
        const res = await firestore.collection('GLOBAL_IMAGES')
            .doc(imageId).delete();
        return res
    }  catch (e) {
        console.log("delImage error: ", e)
        return { error: e }
    }
}